import { useState, useContext, useCallback, useEffect } from "react";
import { DAppContext } from "./context";
import { Toaster } from "react-hot-toast";
import { ReactComponent as Opensea } from "./opensea.svg";
import { ReactComponent as Twitter } from "./twitter.svg";
import { ReactComponent as Etherscan } from "./etherscan.svg";
import Banner from "./banner.png";
import LBanner from "./loved_banner.png";
import Frame from "./frame.png";
import Fin from "./final_banner.png";
import "bootstrap/dist/css/bootstrap.css";

function App() {
  const [count, setCount] = useState(2);
  const [mintedCount, setMintedCount] = useState(0);
  const {
    connectBrowserWallet,
    mint,
    userData,
    transactionHash,
    contractDetails,
  } = useContext(DAppContext);

  const handleCounter = (e) => {
    const { dataset } = e.currentTarget;
    const { pos } = dataset;

    if (pos === "plus" && count < 10) {
      setCount(() => count + 1);
      return;
    }

    if (count - 1 !== 0) {
      setCount(() => count - 1);
    }
  };

  const handleCustomMint = useCallback(() => {
    mint(count);
  }, [count, userData]);

  useEffect(() => {
    if (!!contractDetails) {
      setMintedCount(parseInt(Number(contractDetails.alreadyMinted)));
    }
  }, [contractDetails]);

  return (
    <>
      <header className="header-container socials d-flex align-items-center gap-2 mb-3">
        <a target="_blank" rel="noreferrer" href="https://opensea.io/collection/space-ape-moonclub" className="social-btn">
          <Opensea />
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://twitter.com/mintSAMC"
          className="social-btn"
        >
          <Twitter />
        </a>
        <a target="_blank" rel="noreferrer" href="https://etherscan.io/address/0x20e43cc65377cfa82182f452a3605553abc6c015" className="social-btn">
          <Etherscan />
        </a>
        <div></div>
      </header>
      <section className="banner-container">
        <div className="container">
          <div className="row banner-row">
            <div className="col-lg-6 col-12">
              <h1 className="font-anton">Space Apes are going to the Moon!</h1>
              <p>
                Get your spot on the SpaceShip and be among the best Space
                Community in the NFT world!
              </p>
              <div className="mint-container">
                {!!userData ? (
                  <>
                    <div className="ape-form-counter">
                      <button
                        onClick={handleCounter}
                        type="button"
                        className="ape-btn"
                        data-pos="minus"
                      >
                        -
                      </button>
                      <span> {count} </span>
                      <button
                        onClick={handleCounter}
                        type="button"
                        className="ape-btn"
                        data-pos="plus"
                      >
                        +
                      </button>
                      <button
                        onClick={handleCustomMint}
                        type="button"
                        className="claim-btn"
                      >
                        Mint
                      </button>
                    </div>
                    <div className="ape-claimers">
                      <button
                        onClick={() => mint(3)}
                        type="button"
                        className="claim-btn"
                      >
                        Mint 3
                      </button>
                      <button
                        onClick={() => mint(5)}
                        type="button"
                        className="claim-btn"
                      >
                        Mint 5
                      </button>
                      <button
                        onClick={() => mint(10)}
                        type="button"
                        className="claim-btn"
                      >
                        Mint 10
                      </button>
                    </div>
                    {transactionHash && (
                      <a
                        href={`https://etherscan.io/tx/${transactionHash}`}
                        target="_blank"
                        rel="noreferrer"
                        className="claim-btn d-flex align-items-center"
                      >
                        See transaction
                      </a>
                    )}
                  </>
                ) : (
                  <button
                    onClick={connectBrowserWallet}
                    type="button"
                    className="btn btn-primary btn-connect"
                  >
                    Connect Wallet
                  </button>
                )}
              </div>
            </div>
            <div className="col-lg-5 col-12">
              <img src={Banner} alt="" />
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row about-rows">
            <div className="d-flex align-items-center flex-wrap justify-content-between">
              <div className="col-lg-6 col-12">
                <img src={LBanner} alt="" />
              </div>
              <div className="col-lg-6 col-12">
                <h2 className="font-anton about-title">Better get Minting </h2>
                <p>
                  After seeing the ridiculous stuff happening to the NFT market
                  Space Apes completely lost their faith in humanity and decided
                  to leave the Planet. Next Destination is the Moon! SAMC will
                  be the new Oasis in the new Meta World!
                </p>
              </div>
            </div>
            <div className="d-flex align-items-center flex-wrap justify-content-between">
              <div className="col-lg-6 col-12">
                <h2 className="font-anton about-title">Supply: 6666 </h2>
                <p>
                  The SAMC Spaceship will depart on 23th of June. Time will be
                  specified in our Twitter Account (make sure to follow it!)
                  Hurry up to book your Ticket to the Moon with the Space Apes!
                  The spots are very limited - only 6666
                </p>
              </div>
              <div className="col-lg-6 col-12">
                <img src={Frame} alt="" />
              </div>
            </div>
            <div className="d-flex align-items-center flex-wrap justify-content-between">
              <div className="col-lg-6 col-12">
                <img src={Fin} alt="" />
              </div>
              <div className="col-lg-6 col-12">
                <h2 className="font-anton about-title">#FREEMINT </h2>
                <p>
                  Every one will be provided with one FREE ticket that you can
                  Mint on our website But we cannot take everyone with us so the
                  limit of the tickets will be 10 per wallet! Take your family
                  and friends and mint your tickets to the Moon!
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Toaster position="top-center" reverseOrder={false} />
    </>
  );
}

export default App;
